import * as React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const TutorsPage = ( { location } ) => (
    <Layout currPage='our_tutors' location={location}>
        <Helmet>

            {/*<link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />*/}
            {/*<link href="lib/lightbox/css/lightbox.min.css" rel="stylesheet" />*/}
            {/*<link rel="stylesheet" href="css/font-awesome.min.css" />*/}
        </Helmet>
        <Seo title="tutors overview"/>
        <PageHeader pageTitle="Our tutors" breadCrumbs={[{caption: "home", link: "/"},{caption: "tutors"}]}/>
        <PageBody>
            <p>Tutors:</p>
        </PageBody>
    </Layout>
)

export default TutorsPage
